import styled from "styled-components";
import colors from '../../styles/colors'
import {Link} from 'gatsby-plugin-react-i18next'


export const NavContainer = styled.div`
  background: ${props => props.color};
  height: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top : 0;
  position: sticky;
  @media screen and (max-width: 900px) {
    transition: 0.8s all ease;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1008px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;

  @media screen and (max-width: 1008px) {
    padding: 0 20px;
  }
`;

export const LogoImg = styled.img`
  width: 179px;
  height: 24px;
`;

export const LinkContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuIcon = styled.div`
  display: none;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  border: none;
  @media screen and (max-width: 834px) {
    display: flex;
  }
`;

export const NavMenu = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-family: Euclid-Circular;

  @media screen and (max-width: 470px) {
    button {
      display: none;
    }
  }
`;

export const NavItemLogo = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0;
`;

export const NavItem = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.color};
  text-decoration: none;
  text-align: center;
  font-family: Euclid-Circular;
  font-size: 18px;
  @media screen and (max-width: 834px) {
    display: none;
  }
`;

export const NavItemInter = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.color};
  text-decoration: none;
  text-align: center;
  font-family: Euclid-Circular;
  font-size: 18px;
  @media screen and (max-width: 834px) {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.blocks.eggWhite};
  z-index: 9999999999;
  overflow: hidden;
  padding: 30px 20px;
  box-sizing: border-box;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

export const MenuNav = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const LinksDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const LinkMenu = styled.a`
  height: 40px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.texts.darkBlue};
  text-decoration: none;
  font-family: Euclid-Circular;
  font-size: 18px;
  line-height: 40px;
`;

export const MenuButton = styled.div`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 40px);
  box-sizing: border-box;
`;
