import React from 'react';
import PropTypes from 'prop-types';

export default function English({ width, height, color, title}) {
  return (
        <svg height={height} width={width} viewBox="0 0 473.68 473.68" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g>
        <path d="M41.712 102.641c-15.273 22.168-26.88 47.059-33.918 73.812h107.734l-73.816-73.812z" fill="#29337A"/>
        <path d="M170.511 9.48a235.987 235.987 0 0 0-74.814 37.168l74.814 74.814V9.48z" fill="#29337A"/>
        <path d="M101.261 430.982a235.633 235.633 0 0 0 69.25 33.211v-102.45l-69.25 69.239z" fill="#29337A"/>
        <path d="M10.512 306.771c7.831 25.366 19.831 48.899 35.167 69.833l69.833-69.833h-105z" fill="#29337A"/>
        <path d="M45.619 97.144a241.902 241.902 0 0 0-3.908 5.501l73.816 73.812H7.793c-1.746 6.645-3.171 13.418-4.345 20.284h141.776L45.619 97.144z" fill="#FFF"/>
        <path d="M95.767 427.074c1.802 1.343 3.654 2.621 5.493 3.908l69.25-69.242v102.45c6.653 1.945 13.41 3.624 20.284 4.974V332.05l-95.027 95.024z" fill="#FFF"/>
        <path d="M5.25 286.487c1.47 6.873 3.205 13.642 5.258 20.284h105.001l-69.833 69.833a238.435 238.435 0 0 0 25.168 29.12L190.08 286.487H5.25z" fill="#FFF"/>
        <path d="M170.511 9.48v111.982l-74.815-74.81c-10.314 7.67-19.955 16.185-28.888 25.403l123.983 123.983V4.506c-6.87 1.358-13.627 3.041-20.28 4.974z" fill="#FFF"/>
        <path d="M170.511 306.056l-.711.715h.711z" fill="#D32030"/>
        <path d="M190.084 286.487h.71v-.714z" fill="#D32030"/>
        <path d="M281.229 196.737h-.684v.688z" fill="#D32030"/>
        <path d="M171.21 176.457l-.699-.703v.703z" fill="#D32030"/>
        <path d="M190.794 196.037v.7h.7z" fill="#D32030"/>
        <g>
          <path d="M300.825 411.764v53.091a235.482 235.482 0 0 0 70.211-32.897l-57.526-57.526c-4.597 16.151-6.279 24.501-12.685 37.332z" fill="#252F6C"/>
          <path d="M313.812 108.471l62.799-62.799a235.938 235.938 0 0 0-75.787-36.854v54.538c7.386 14.79 8.007 26.028 12.988 45.115z" fill="#252F6C"/>
          <path d="M427.029 377.984c15.815-21.275 28.141-45.29 36.147-71.213h-107.36l71.213 71.213z" fill="#252F6C"/>
          <path d="M465.887 176.457c-7.188-27.318-19.143-52.676-34.898-75.192l-75.2 75.192h110.098z" fill="#252F6C"/>
        </g>
        <g>
          <path d="M327.638 290.5l16.275 16.275 77.903 77.903c1.769-2.214 3.526-4.42 5.217-6.69l-71.213-71.213h107.36c2.046-6.638 3.784-13.41 5.25-20.284H329.16c-.228 2.876-1.249 1.152-1.522 4.009z" fill="#E7E7E7"/>
          <path d="M311.352 120.348l70.607-70.615a245.581 245.581 0 0 0-5.348-4.061l-62.799 62.799c.651 2.483-3.066 9.334-2.46 11.877z" fill="#E7E7E7"/>
          <path d="M300.825 58.992V8.814a236.39 236.39 0 0 0-20.284-4.727v24.476c7.547 8.182 14.312 18.459 20.284 30.429z" fill="#E7E7E7"/>
          <path d="M326.041 196.737h144.195c-1.171-6.866-2.599-13.635-4.345-20.284H355.793l75.2-75.192a238.044 238.044 0 0 0-24.584-29.696l-84.702 84.694c2.281 15.363 3.302 24.285 4.334 40.478z" fill="#E7E7E7"/>
          <path d="M310.088 371.002l60.952 60.959c10.138-6.982 19.685-14.753 28.593-23.189l-80.173-80.177c-2.559 14.828-5.595 29.15-9.372 42.407z" fill="#E7E7E7"/>
          <path d="M280.545 442.301v27.28a233.85 233.85 0 0 0 20.284-4.727v-53.091c-5.976 11.975-12.741 22.367-20.284 30.538z" fill="#E7E7E7"/>
        </g>
        <path d="M321.707 156.259l84.694-84.694a236.803 236.803 0 0 0-24.446-21.832l-66.55 66.561c2.958 12.363 4.301 26.514 6.302 39.965z" fill="#D71F28"/>
        <g>
          <path d="M225.019.292z" fill="#D32030"/>
          <path d="M236.836 473.68c-3.938 0-7.872-.108-11.81-.299 3.916.198 7.85.299 11.81.299z" fill="#D32030"/>
          <path d="M236.836 473.68c14.943 0 29.535-1.447 43.708-4.099v-27.28c-12.441 13.485-26.995 31.379-43.708 31.379z" fill="#D32030"/>
        </g>
        <g>
          <path d="M470.232 196.737H327.911c1.885 29.704 1.657 60.249-.681 89.75h141.2a237.59 237.59 0 0 0 5.25-49.643c0-13.68-1.219-27.06-3.448-40.107z" fill="#D71F28"/>
          <path d="M327.638 290.5c-1.316 13.994-5.901 24.898-8.182 38.099l80.173 80.173a239.086 239.086 0 0 0 22.183-24.094l-77.9-77.907-16.274-16.271z" fill="#D71F28"/>
        </g>
        <path d="M280.545 30.324V4.091C266.376 1.447 251.784 0 236.836 0c16.713 0 31.267 16.843 43.709 30.324z" fill="#D32030"/>
        <g>
          <path d="M300.825 422.007c6.406-12.834 11.899-27.609 16.499-43.757l-16.499-16.499v60.256z" fill="#29337A"/>
          <path d="M319.377 102.906c-4.989-19.087-11.166-36.439-18.552-51.229v69.773l18.552-18.544z" fill="#29337A"/>
        </g>
        <g>
          <path d="M332.234 295.092c.269-2.857.512-5.725.744-8.605h-9.349l8.605 8.605z" fill="#FFF"/>
          <path d="M300.825 121.451V51.674c-5.976-11.97-12.737-22.254-20.284-30.429v129.906l40.735-40.735a457.616 457.616 0 0 0-1.9-7.517l-18.551 18.552z" fill="#FFF"/>
          <path d="M281.229 196.737h52.429c-1.028-16.192-2.666-32.123-4.944-47.482l-47.485 47.482z" fill="#FFF"/>
          <path d="M280.545 452.432c7.547-8.182 14.308-18.459 20.284-30.429v-60.256l16.499 16.499c3.784-13.264 6.959-27.434 9.525-42.261l-46.307-46.304-.001 162.751z" fill="#FFF"/>
        </g>
        <path d="M280.545 452.432V289.681l46.304 46.307c2.277-13.205 4.069-26.899 5.381-40.896l-8.605-8.605h9.349c2.337-29.502 2.565-60.047.681-89.75h-52.429l47.482-47.482c-2.001-13.455-4.476-26.469-7.434-38.836l-40.728 40.735V21.248C268.103 7.763 253.549 0 236.836 0c-3.938 0-7.872.101-11.817.292a238.416 238.416 0 0 0-34.225 4.215v191.531L66.808 72.055a239.134 239.134 0 0 0-21.189 25.089l79.313 79.313 20.291 20.284H3.448C1.227 209.784 0 223.164 0 236.844c0 17.034 1.84 33.626 5.25 49.643h184.834L70.847 405.724a238.535 238.535 0 0 0 24.921 21.349l95.023-95.023v137.116a238.638 238.638 0 0 0 34.232 4.215c3.938.191 7.872.299 11.81.299 16.716 0 31.27-7.763 43.712-21.248z" fill="#E51D35"/>
      </g>
    </svg>
  );
};

English.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
  
English.defaultProps = {
  height: '24',
  width: '24',
  color: '#ffffff',
  title: "english"
};