import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { 
  MenuContainer, 
  MenuNav,
  NavItemLogo,
  MenuIcon,
  LinksDiv,
  LinkMenu, 
  MenuButton,
 } from './StyledElements'
import Logo from '../../assets/SVG/Logo'
import colors from '../../styles/colors'
import Cross from '../../assets/SVG/Cross';
import Button from '../../UIComponents/Button'

const Menu = ({ isOpen, onClick, handleClickPlatform }) => {
  const { t } = useTranslation();
  
  return (
    <MenuContainer isOpen={isOpen} >
      <MenuNav>
        <NavItemLogo to="/">
          feedback - evolt
          <Logo color={colors.blocks.darkBlue}/>
        </NavItemLogo>
        <MenuIcon onClick={onClick}>
          <Cross/>
        </MenuIcon>
      </MenuNav>
        <LinksDiv>
          <LinkMenu
            href="https://platform.evoltapp.com"
            onClick={() => handleClickPlatform("https://platform.evoltapp.com", 'login')}
          >
            {t("Login")}
          </LinkMenu>
          <LinkMenu
            href="https://evolt.io/about/"
            onClick={() => handleClickPlatform("https://evolt.io/about/", 'about-us')}
            >
            {t("About")}
          </LinkMenu>
        </LinksDiv>
        <MenuButton>
          <Button
            width="100%"
            label={t('Demo')}
            onClick={() => handleClickPlatform("https://platform.evoltapp.com", 'free-try')}
          />
        </MenuButton>
    </MenuContainer>
  )
}

Menu.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  handleClickPlatform: PropTypes.func,
};

Menu.defaultProps = {
  isOpen: false,
  onClick: PropTypes.func,
  handleClickPlatform: PropTypes.func,
};

export default Menu;