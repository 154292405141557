import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { 
  NavContainer, 
  Content, 
  MenuIcon,
  NavMenu,
  NavItemLogo,
 } from './StyledElements'
import Logo from '../../assets/SVG/Logo'
import Bars from '../../assets/SVG/Bars'
import Button from '../../UIComponents/Button'
import colors from '../../styles/colors'
import Menu from './Menu';
import { navigate } from 'gatsby';
import Language from '../../UIComponents/Language';

const Colors = {
  dark: {
    logo: colors.blocks.white,
    background: colors.blocks.darkBlue,
    text: colors.blocks.white
  },
  light: {
    logo: colors.blocks.darkBlue,
    background: colors.blocks.white,
    text: colors.blocks.darkBlue
  }
}

const Navbar = ({ type }) => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);
  const [ change, setChange] = useState(false);

  const handleTouch = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleClickButton = async (link, type) => {
    if (type)
      await window.analytics.track('book-demo', {
        type
      });
    if (link)
      navigate(link);
  };

  const changeNavbarColor = () =>{
    if(window.scrollY >= 80){
      setChange(true);
    }
    else{
      setChange(false);
    }
 };

  const colorInfos = ( type ) => {
    if (change)
      return Colors[type=== "dark" ? "light" : "dark"]
    return Colors[type];
  }

 useEffect(() => {
   window.addEventListener('scroll', changeNavbarColor);
 },[])

  const finalColors = colorInfos(type);

  return (
    <React.Fragment>
      <NavContainer color={finalColors.background}>
        <Content>
          <NavItemLogo to="/">
            feedback - evolt
            <Logo color={finalColors.logo}/>
          </NavItemLogo>
          <NavMenu>
          <Button
            onClick={() => handleClickButton("https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1", 'navbar')}
            label={t("Demo")}
          />
          <Language/>
          <MenuIcon
            onClick={() => handleTouch()}
          >
            <Bars color={finalColors.logo}/>
          </MenuIcon>
          </NavMenu>
        </Content>
      </NavContainer>
      <Menu
        isOpen={open}
        onClick={handleClose}
        handleClickPlatform={handleClickButton}
      />
    </React.Fragment>
  )
}

Navbar.propTypes = {
  type: PropTypes.string
};

Navbar.defaultProps = {
  type: "dark"
};

export default Navbar;