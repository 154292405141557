import * as React from "react"
import JsonLd from '../../UIComponents/JsonLd';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const HeaderHtml = ({ page }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  
  return (
    <main>
      <Helmet>
        <html lang={language} />
        <title>{t(`${page}.title`)}</title>
        <meta name="google-site-verification" content="oVki5151ZS_OeaTgM2CQylS0Wd8eoC6jC2fHgxC7a3s" />
        <meta charSet="utf-8" />
        <meta name="description" content={t(`${page}.description`)}/>
        <JsonLd>
            {{
              '@context': 'https://transfo.evolt.io/',
              '@type': 'Organization',
              url: 'https://transfo.evolt.io',
              name: 'transfo evolt',
              contactPoint: {
                '@type': 'ContactPoint',
                email: 'jonathan.scanzi@evolt.io',
                contactType: 'Customer service',
              },
            }}
        </JsonLd>
         <script>
          {
            `
              window.axeptioSettings = {
                clientId: "60dae79b7559213aac063b43",
              };
              
              (function(d, s) {
                var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                t.parentNode.insertBefore(e, t);
              })(document, "script");

              void 0 === window._axcb && (window._axcb = []);

              window._axcb.push(function(axeptio) {
                axeptio.on("cookies:complete", function(choices) {
                  if (choices.intercom)
                    launchIntercom();
                  if (choices.google_analytics && window.analytics && window.analytics.load)
                    window.analytics.load("OqhFWeCMT4rrCToEdSgvp1EyUVrVDRE3");
                })
              })
            `
          }
        </script>
        <script>
          {
            `
            var APP_ID = "g4447j8s";

            window.intercomSettings = {
              app_id: APP_ID
            };
            function launchIntercom() {
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            }
            `
          }
        </script>
        <script>
        {
          `
          function launchAnalytics () {
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="OqhFWeCMT4rrCToEdSgvp1EyUVrVDRE3";analytics.SNIPPET_VERSION="4.13.2";
              analytics.load("OqhFWeCMT4rrCToEdSgvp1EyUVrVDRE3");
              analytics.page();
              }}();
          }
          `
        }
      </script>
      </Helmet>
    </main>
  );
};

HeaderHtml.propTypes = {
  page: PropTypes.string,
}

export default HeaderHtml;