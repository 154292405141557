import * as React from "react"
import PropTypes from 'prop-types'

export default function Logo({ width, height, color, title }) {
  return (
    <svg height={height} width={width} viewBox="0 0 153 27" xmlns="http://www.w3.org/2000/svg">
	    <title>{title}</title>
	    <g fill="none">
        <path d="M8.849 8.275c3.574 0 6.575 2.315 6.575 6.598 0 .293 0 .532-.027.958H4.674c.136 2.181 1.937 3.618 4.202 3.618 1.91 0 3.192-.851 3.902-2.022l2.292 1.596c-1.228 1.889-3.357 3.086-6.221 3.086-4.148 0-7.122-2.873-7.122-6.917 0-3.91 2.974-6.917 7.122-6.917zm-4.037 5.428h7.584c-.328-1.916-1.91-2.952-3.657-2.952-1.746 0-3.52.983-3.929 2.952h.002z" fill={color}/>
        <path d="M16.324 8.541h3.33l4.146 9.313 4.175-9.313h3.356l-6.194 13.302h-2.646L16.324 8.541z" fill={color}/>
        <path d="M39.244 22.11c-4.037 0-7.148-3.06-7.148-6.918 0-3.857 3.11-6.917 7.148-6.917s7.15 3.06 7.15 6.917c0 3.857-3.112 6.917-7.15 6.917zm0-2.714c2.401 0 4.093-1.836 4.093-4.204 0-2.368-1.692-4.203-4.093-4.203-2.428 0-4.12 1.836-4.12 4.203 0 2.368 1.692 4.204 4.12 4.204z" fill={color}/>
        <path d="M49.421 1.89h3.002v19.953H49.42V1.891z" fill={color}/>
        <path d="M62.382 21.976c-2.62 0-4.666-1.41-4.666-4.416V11.15h-2.838V8.542h2.838V4.844h3.001v3.698h3.902v2.607h-3.902v5.932c0 1.65.737 2.262 2.292 2.262a4.96 4.96 0 0 0 1.61-.267v2.608c-.6.186-1.31.292-2.237.292z" fill={color}/>
        <path d="M69.063 22.051c1.312 0 2.375-1.036 2.375-2.316 0-1.279-1.063-2.316-2.375-2.316s-2.376 1.037-2.376 2.316c0 1.28 1.064 2.316 2.376 2.316z" fill={color}/>
        <path d="M10.249 1.892c0 .398-.106.789-.306 1.136-.2.346-.489.637-.838.843a2.421 2.421 0 0 1-2.329.075L5.701 5.958c.67.341 1.416.519 2.172.518 2.597 0 4.995-2.053 4.995-4.584h-2.62z" fill={color}/>
        <path d="M87.708 19.567l-1.629-.91c-.608 1.235-1.824 2.08-3.388 2.08-2.26 0-3.801-1.734-3.801-3.901s1.542-3.901 3.8-3.901c1.565 0 2.781.845 3.39 2.08l1.628-.91c-.955-1.734-2.78-2.904-5.017-2.904-3.28 0-5.691 2.492-5.691 5.635 0 3.142 2.411 5.635 5.69 5.635 2.238 0 4.063-1.17 5.018-2.904z" fill={color}/>
        <path d="M90.129 22.254h1.868v-5.375c0-2.535 1.39-3.966 3.15-3.966 1.52 0 2.562 1.127 2.562 2.948v6.393h1.846v-6.675c0-2.687-1.672-4.378-4.105-4.378-1.412 0-2.715.607-3.453 1.886V6h-1.868v16.254z" fill={color}/>
        <path d="M102.072 16.836c0 3.099 2.281 5.635 5.322 5.635 1.629 0 3.106-.846 3.822-1.886v1.669h1.868V11.418h-1.868v1.669c-.716-1.04-2.193-1.886-3.822-1.886-3.041 0-5.322 2.536-5.322 5.635zm9.296 0c0 2.189-1.498 3.923-3.692 3.923s-3.714-1.734-3.714-3.923 1.52-3.923 3.714-3.923 3.692 1.734 3.692 3.923z" fill={color}/>
        <path d="M116.601 22.254h1.868v-5.375c0-2.535 1.39-3.966 3.149-3.966 1.521 0 2.563 1.127 2.563 2.948v6.393h1.846v-6.675c0-2.687-1.672-4.378-4.105-4.378-1.412 0-2.715.607-3.453 1.886v-1.669h-1.868v10.836z" fill={color}/>
        <path d="M139.513 21.625V11.418h-1.868v1.669c-.738-1.084-2.172-1.886-3.779-1.886-3.02 0-5.3 2.428-5.3 5.505 0 3.099 2.28 5.526 5.3 5.526 1.607 0 3.041-.823 3.779-1.907v1.235c0 2.601-1.781 3.75-3.627 3.75-1.673 0-2.933-.716-3.801-1.82l-1.304 1.3c.934 1.213 2.781 2.21 5.126 2.21 3.085 0 5.474-1.755 5.474-5.375zm-9.057-4.92c0-2.166 1.52-3.792 3.67-3.792 2.172 0 3.671 1.626 3.671 3.793 0 2.189-1.499 3.793-3.671 3.793-2.15 0-3.67-1.604-3.67-3.793z" fill={color}/>
        <path d="M142.183 16.836c0 3.186 2.259 5.635 5.626 5.635 2.259 0 3.909-.997 4.887-2.58l-1.477-1.018c-.673 1.149-1.716 1.886-3.389 1.886-2.15 0-3.692-1.518-3.757-3.555h8.905c.022-.238.022-.368.022-.563-.022-3.468-2.324-5.44-5.17-5.44-3.301 0-5.647 2.47-5.647 5.635zm5.582-3.988c1.651 0 3.063 1.062 3.324 2.84h-6.886c.348-1.8 1.933-2.84 3.562-2.84z" fill={color}/>
      </g>
    </svg>
  );
}

Logo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

Logo.defaultProps = {
  height: '24',
  width: '145',
  color: "#091732",
  title: "Change Evolt"
};