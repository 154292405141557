import colors from '../../styles/colors';

const buttonTypes = {
    main: {
      background: colors.buttons.blue,
      color: colors.buttons.white,
      border: 'transparent'
    },
    secondary: {
      background: 'transparent',
      color: colors.buttons.white,
      border: colors.buttons.white,
    }
}

export default buttonTypes;