import React from 'react';
import PropTypes from 'prop-types';

export default function French({ width, height, color, title}) {
  return (
    <svg height={height} width={width} viewBox="0 0 473.684 473.684" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g>
        <path d="M315.802 13.535l-27.639 76.632c25.512 84.193 25.512 209.156 0 293.353l27.639 76.624c91.975-32.523 157.882-120.195 157.882-223.31 0-103.099-65.907-190.775-157.882-223.299z" fill="#E61E25"/>
        <path d="M315.802 90.167V13.535C291.102 4.8 264.536.002 236.84.002c36.521 0-14.117 123.773 78.962 90.165z" fill="#E4E4E4"/>
        <path d="M236.84 473.682c27.695 0 54.262-4.798 78.962-13.534v-76.624c-92.144-9.196-78.962 90.158-78.962 90.158z" fill="#E4E4E4"/>
        <path d="M0 236.837C0 340.297 66.355 428.2 158.806 460.461V13.229C66.355 45.49 0 133.393 0 236.837z" fill="#3757A6"/>
        <path d="M315.802 90.167V383.52c25.515-84.197 25.515-209.161 0-293.353z" fill="#EF4F4E"/>
        <path d="M315.802 383.523V90.167C299.677 36.938 273.361.002 236.84.002a236.342 236.342 0 0 0-78.034 13.227v447.234a236.338 236.338 0 0 0 78.034 13.22c36.521-.001 62.837-36.937 78.962-90.16z" fill="#F3F4F5"/>
      </g>
    </svg>
  );
};

French.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
  
French.defaultProps = {
  height: '24',
  width: '24',
  color: '#ffffff',
  title: "French"
};