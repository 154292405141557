const colors = {
  blocks: {
    darkBlue: '#091732',
    gradientBlue: "linear-gradient(20deg, hsla(218, 100%, 85%, 1), hsla(268, 100%, 85%, 1))",
    gradientPink : "linear-gradient(136.91deg, #FFE7E5 -3.38%, #F1E6FF 115.98%)",
    white: '#FFFFFF',
    eggWhite: '#F1F4F8',
    Eveiapink: '#FFE7E6',
    blue: '#0555FF',
    lightBlue: '#E0E9FE',
    greyBlue: "#F1F4F8"
  },
  texts: {
    black: '#26282D',
    salmon:'#FF8A80',
    white: '#ffffff',    
    darkBlue: '#091732',
    blue: '#0555FF',
    darkerBlack: '#091732',
    darkOrange: '#FF9C4C'
  },
  titles: {
    purple: '#AA62FF',
    darkBlue: '#091732',
    white: '#FFFFFF',
    black: '#26282D',
    blue: '#0555FF',
    darkerBlack: '#091732',
    orangeToPink: 'linear-gradient(96.76deg, #AA62FF, #FC3D74)'
  },
  buttons: {
    blue: '#0555FF',
    white: '#FFFFFF',
    orange: '#FFEDD3'
  },
  borders: {
    darkBlue: '#13326C',
    white: '#ffffff',
    blue: '#0555FF',
    grey: '#222B37',
    secondBlue : '#256BFE',
    darkerGrey: '#E0E5E9'
  }
}

export default colors