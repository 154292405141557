import React, { useState } from 'react';
import PropTypes from 'prop-types'
import {
  Container,
  Menu,
  Lang
} from './StyledElements';
import French from '../../assets/SVG/French';
import English from '../../assets/SVG/English';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const Language = ({ marginLeft }) => {
  const [ open, setOpen ] = useState(false);
  const { t } = useTranslation();
  const { language, changeLanguage } = useI18next();

  const changeLang = (lang) => {
    setOpen(false);
    if (lang !== language)
      changeLanguage(lang);
  }

  return (
    <Container
      marginLeft={marginLeft}
      onClick={() => setOpen(!open)}
    >
      {
        language === 'en' ? <English/> : <French/>
      }
      <Menu open={open}>
      <Lang onClick={() => changeLang('fr')}>
        <French/>
        <p>{t('French')}</p>
      </Lang>
      <Lang onClick={() => changeLang('en')}>
        <English/>
        <p>{t('English')}</p>
      </Lang>
      </Menu>
    </Container>
  );
};

Language.propTypes = {
  marginLeft: PropTypes.number
};

Language.defaultProps = {
  marginLeft: 16
};

export default Language;