import React from 'react'
import PropTypes from 'prop-types';
import buttonType from './ButtonTypes'
import {
  ButtonContainer
} from './StyledElements';

const Button = ({ label, top, onClick, type, width }) => {
  const typeButton = buttonType[type] ?  buttonType[type] : buttonType.main;
  return (
    <ButtonContainer {...typeButton} width={width} onClick={onClick} top={top} type={type} >
      {label}
    </ButtonContainer>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  top: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'main',
  onClick: () => {}
};

export default Button;