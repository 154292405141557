import React from 'react';
import PropTypes from 'prop-types';

const Bars = ({height, width, color, title}) => {	
	return (
		<svg height={height} width={width} viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="none">
        <path d="M18 0H2a1.333 1.333 0 1 0 0 2.667h16A1.333 1.333 0 1 0 18 0z" fill={color}/>
        <path d="M18 6.667H2a1.333 1.333 0 1 0 0 2.666h16a1.333 1.333 0 1 0 0-2.666z" fill={color}/>
        <path d="M18 13.333H2A1.333 1.333 0 1 0 2 16h16a1.333 1.333 0 1 0 0-2.667z" fill={color}/>
      </g>
    </svg>
	);
};

Bars.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

Bars.defaultProps = {
  height: '16',
  width: '20',
  color: '#ffffff',
  title: "Innovation management"
};

export default Bars;